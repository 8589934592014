import * as React from 'react';
import SliderItem from '../SliderItem';
import { Box, Container } from '@material-ui/core';
import { Translate } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SlideButton from '../SliderButton';
import { ESTATUS_EGRESO, ESTATUS_EN_PROCEDIMIENTO, ESTATUS_LISTO_EN_SALA } from '../../../utils/constantes';
import { getFormatoFecha } from '../../../utils/moment';
import SliderFilter from '../SliderFilter';

const useStyles = makeStyles({

  root: {
    position: "relative",
    overflow:"hidden",
    backgroundColor: "transparent",
    padding: "20px 0",

  },
  row: {
    display:"flex",
    position: "relative",
    backgroundColor: "transparent",
    padding: "0 45px",

  },
 
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  tr:{
    background: "#f1f1f1",
    '&:hover': {
      background: "#f00",
    }
  }
});




export default function Page(props) {
  const [checked, setChecked] = React.useState(localStorage.getItem('procedsFilter') !== null ? JSON.parse(localStorage.getItem('procedsFilter')) : []);


  const classes = useStyles();

  React.useEffect(() => {
   // const procListFiltro = localStorage.getItem('procListFiltro');
   // const dataFiltro =procListFiltro !== null ? JSON.parse(procListFiltro) : [];
   // console.log("procListFiltro:"+dataFiltro);
   // setCart(localData && localData.length > 0 ? JSON.parse(localData): []);
   setChecked(localStorage.getItem('procedsFilter') !== null ? JSON.parse(localStorage.getItem('procedsFilter')) : []);
  }, [props.citas])

  return ( 
       <Box sx={{marginTop:"1rem"}} >
        <Box sx={{display:"flex",flexDirection:"row",justifyContent:"flex-start",gridColumnGap:"2rem"}}>
          {
            props.citas.map((item,index)=>{
              const newValue = checked.find((value)=> value.id === item.CAT_TIPO_CITA_id );
              console.log("checked.newValue[container]:"+newValue);

              if(newValue!==undefined && item.CAT_ESTADO_CITA_id!==ESTATUS_EGRESO && item.fecha===getFormatoFecha(new Date())){
                return <SliderItem key={index} 
                                   id={index} 
                                   cita={item} 
                                   setCitaSala={props.setCitaSala} 
                                   citaSala={props.citaSala}/>
              } 
            })
          }     
        <SlideButton />
        <SlideButton />
        </Box>
        <SliderFilter />
       </Box>                
  ) 
  
}