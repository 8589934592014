import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Avatar, CardHeader, CardMedia, Chip, Fab, IconButton, Snackbar } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';
import RemoveRedEyeTwoToneIcon from '@material-ui/icons/RemoveRedEyeTwoTone';
import { useRef } from 'react';
import EmojiPeopleTwoToneIcon from '@material-ui/icons/EmojiPeopleTwoTone';
import AlbumTwoToneIcon from '@material-ui/icons/AlbumTwoTone';
import { useState } from 'react';
import {ESTATUS_AGENDADA, ESTATUS_EN_PROCEDIMIENTO, ESTATUS_EN_RECUPERACION, ESTATUS_EN_SALA_DE_ESPERA, ESTATUS_LISTO_EN_SALA, FEMENINO, MASCULINO} from "../../../utils/constantes"
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Sound1 from "../../../audio/1.mp3"
import Sound3 from "../../../audio/3.mp3"
import camaPNG from "../../../icons/cama.png"
import salaEsperaPNG from "../../../icons/esperando.png";
import procedimientoPNG from "../../../icons/cuarto-de-cirugia.png";
import listoProcedimientoPNG from "../../../icons/operacion-plastica.png";
import agendadaPNG from "../../../icons/cita-medica.png";
import ReactHowler from 'react-howler';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      transform: "scale(1.0) !important",
    },
    flex: "0 0 19.7%",
    textAlign: "center",
    margin: "0 6px",
    transition: "transform 300ms ease 100ms",
    height:"22rem",
    width:"16rem",
    borderColor: "green",
    borderRadius: 15,
    position: 'relative',
    alignItems: "center",
    justifyContent: "center",
    

  },
  content: {
    flex: '1 0 auto',
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    padding: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    left: theme.spacing(3),
  },
  fabcenter: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

export default function Page(props) {

  const [open, setOpen] = React.useState(false);
  const [openEnSala, setOpenEnSala] = React.useState(false);
  const [mensaje, setMensaje] = React.useState("");
  const [mensajeEnSala, setMensajeEnSala] = React.useState("");
  
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleCloseEnSala = () => {
    setOpenEnSala(false);
  };
  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const containerRef = useRef(null);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  let nombrePaciente="";
  let nombrePacienteMensaje="";
  let nombreMedico="";
  let tipoCita="";
  let estatusCita="";
  let edad="";
  let codColorStatus=""
  let colorFace="#90a4ae";
  let elevacion=3;
  let border="";
  let transform= "";
  let iconStatus=<EmojiPeopleTwoToneIcon alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.large} style={{color:'white'}}/>;


  if(props.medico!==undefined){
    nombreMedico=<Typography variant='h6' style={{ textTransform:"uppercase"}}>
                  {props.medico.titulo+" "+props.medico.nombres.charAt(0)+" "+props.medico.apellidoPaterno.charAt(0)+" "+props.medico.apellidoMaterno.charAt(0)}
                 </Typography>
  }
  
  if(props.paciente!=undefined){
    nombrePaciente=<Typography variant='body1' style={{ textTransform:"uppercase",fontWeight:"bolder",padding:"3px 0"}}>
                    {props.paciente.nombres+" "+props.paciente.apellidoPaterno+" "+props.paciente.apellidoMaterno} 
                   </Typography>
    nombrePacienteMensaje=<Typography variant='h5' style={{ textTransform:"uppercase",fontWeight:"bolder",padding:"3px 0"}}>
                            {props.paciente.nombres+" "+props.paciente.apellidoPaterno+" "+props.paciente.apellidoMaterno} 
                          </Typography>               
  }

  if(props.catTipoCita!==undefined){
    tipoCita=<Typography variant='body1' style={{ textTransform:"uppercase",backgroundColor: props.codColor ,color: "whitesmoke",}}>
              {props.catTipoCita.nombre}
             </Typography>
  }

  if(props.catEstadoCita!==undefined){
    estatusCita=props.catEstadoCita.nombre;
    codColorStatus=props.catEstadoCita.codColor;
  }

  if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_LISTO_EN_SALA || props.cita.CAT_ESTADO_CITA_id===ESTATUS_EN_PROCEDIMIENTO){ 
    elevacion=5;
    border= "solid 4px blue";
    transform= "scale(1.0)";

  }
  if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_EN_SALA_DE_ESPERA){ 
    elevacion=5;
    border= "solid 4px yellow";
    transform= "scale(1.0)";

  }

  if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_EN_SALA_DE_ESPERA){ 
    iconStatus=<Avatar alt="Remy Sharp" src={salaEsperaPNG} className={classes.large}  style={{backgroundColor:'white'}}/>;
  }else if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_EN_RECUPERACION){
    iconStatus=<Avatar alt="Remy Sharp" src={camaPNG} className={classes.large} style={{backgroundColor:'white'}}/>;
  } if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_EN_PROCEDIMIENTO){
    iconStatus=<Avatar alt="Remy Sharp" src={procedimientoPNG} className={classes.large} style={{backgroundColor:'white'}}/>;
  }if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_LISTO_EN_SALA){
    iconStatus=<Avatar alt="Remy Sharp" src={listoProcedimientoPNG} className={classes.large} style={{backgroundColor:'white'}}/>;
  }if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_AGENDADA){
    iconStatus=<Avatar alt="Remy Sharp" src={agendadaPNG} className={classes.large} style={{backgroundColor:'white'}}/>;
  }

  

  if(props.paciente!==undefined){          
    console.log("props.paciente.sexo:"+props.paciente.SEXO_id);
    if(props.paciente.SEXO_id===MASCULINO){
      colorFace="#00aae4";
    }else if(props.paciente.SEXO_id===FEMENINO){
      colorFace="#fe4164";
    }  
  }


    useEffect(() => {


     // containerRef.current.handleClick();
     if(containerRef.current!==undefined){
      //containerRef.current.focus();
      //containerRef.current.blur();
      //containerRef.current.click();
     }
      console.log("RENDERIANDO 1:"+props.cita.CAT_ESTADO_CITA_id);

      if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_LISTO_EN_SALA ||props.cita.CAT_ESTADO_CITA_id=== ESTATUS_EN_PROCEDIMIENTO){
        props.setCitaSala(
          (prev) => {
            return props.cita;
          }
          );
      }else{
        if(props.cita.id===props.citaSala.id){
          props.setCitaSala(
            (prev) => {
              return {};
            }
            );
        }
      } 

    }, [props.cita]);

    useEffect(() => {

      if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_EN_SALA_DE_ESPERA){ 
        setOpen(true);
        setMensaje(nombrePacienteMensaje);        
      }

      if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_LISTO_EN_SALA ){
        setOpenEnSala(true);
        setMensajeEnSala(nombrePacienteMensaje);        
      }

    }, [props.cita.CAT_ESTADO_CITA_id]);

  return <div >

<Card  className={classes.root} elevation={elevacion} style={{border:border,transform:transform}}>
    <Fab aria-label="add" className={classes.fab} style={{backgroundColor: codColorStatus}} >
     <AlbumTwoToneIcon   style={{color:'white'}}/>    
    </Fab>
      <CardContent  className={classes.content} >
        <Chip 
          variant="default" 
          label={<Typography 
                    variant='h6' 
                    style={{ textTransform:"uppercase",
                            overflow: "hidden",
                            textOverflow: "ellipsis",                              
                            }}
                 >
                  {tipoCita}
                 </Typography>}
          size="medium" 
          style={{padding:"0.3rem",backgroundColor: props.codColor ,color: "whitesmoke",maxWidth: 225}} 
          />  
  
      </CardContent>
      <Fab aria-label="add" style={{backgroundColor: colorFace}} size="large" variant="round" className={classes.fabcenter}>
        {iconStatus}
      </Fab>  
      <CardContent  className={classes.content} >
  
        <Chip  
                              ref={containerRef}  
                              variant="outlined"                                           
                              icon={<QueryBuilderIcon style={{color:"#009688"}}/>} 
                              label={  <Typography variant='body1' style={{ textTransform:"uppercase"}}>{props.cita.horaInicio.substring(0,5)+" - "+props.cita.horaFin.substring(0,5)}</Typography>} 
                              style={{maxWidth: 210,backgroundColor:"",color:"#009688"}} 
                              onClick={handleClick}
                            />   
      {nombreMedico}

      {nombrePaciente}     

      </CardContent>
      
    </Card>
    <Snackbar
        autoHideDuration={8000} 
        onClose={handleClose}
      
        open={open}
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snackbarStyleViaContentProps
        }}
        message={
          <span id="message-id">
            <div>{mensaje}</div>
          </span>
        }
      /> 
    <ReactHowler
        src={Sound1}
        playing={open}        
      />
      
    <Snackbar
        autoHideDuration={8000} 
        onClose={handleCloseEnSala}
      
        open={openEnSala}
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snackbarStyleViaContentProps
        }}
        message={
          <span id="message-id">
            <div>{mensajeEnSala}</div>
          </span>
        }
      />          

      
    <ReactHowler
        src={Sound3}
        playing={openEnSala}        
      />
  </div>
  
}