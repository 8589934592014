import React,{Component} from "react";
import DisplayTV from "../DisplayTV";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Paper } from "@material-ui/core";
import LogoFloat from "./LogoFloat";

import { makeStyles} from "@material-ui/styles";


const useStyles = makeStyles({

    root: {
        width: "100wh",
        height: "100vh",
        color: "#fff",
        background: "linear-gradient(-45deg,#00b8ff,#0097e1,#0876b8,#004fa7,#012e6d)",
        backgroundSize: "400% 400%",
        animation: `$myEffect 15s ease infinite`

    },
    "@keyframes myEffect": {
        "0%": {
            backgroundPosition: "0% 50%"
            },
          "50%": {
            backgroundPosition: "100% 50%"
          },
          "100%": {
            backgroundPosition: "0% 50%"
          }
      },
    row: {
      display:"flex",
      position: "relative",
      backgroundColor: "transparent",
      padding: "0 45px",
  
    },
   
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    tr:{
      background: "#f1f1f1",
      '&:hover': {
        background: "#f00",
      }
    }
  });

 function Page(props) {
      const classes = useStyles();

        let loading=props.loading;
        var animacionCargando;
        if (loading) {
            animacionCargando = <Box color="text.primary">
                                    <LinearProgress color="secondary"/>
                                </Box>
           
                               
        }else{
            animacionCargando= <Box color="text.primary">
                                    <LinearProgress variant="determinate" value={0} />
                                </Box>
        }



        return     <Box className={classes.root}  sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"stretch" }}>
                        <DisplayTV citas={props.citaes}/>
                        <LogoFloat />
                    </Box>  
                            
               
             
}
export default Page;
