export const ID_ROL_MEDICO="1";
export const ID_ROL_PACIENTE="3";


export const PROFIL_MEDICO="MEDICO";
export const PROFIL_ADMINISTRADOR="ADMINISTRADOR";
export const PROFIL_PACIENTE="PACIENTE";
export const PROFIL_ROOT="ROOT";

export const ROLES_ERLAUB_ARRAY=[PROFIL_MEDICO,PROFIL_ADMINISTRADOR];


export const ESTATUS_EN_SALA_DE_ESPERA="7";
export const ESTATUS_EGRESO="10";
export const ESTATUS_AGENDADA="1";
export const ESTATUS_ENVIADA="11"; //(21oct22)Medico envia cita para que el admnistrador la autorice.
export const ESTATUS_LISTO_EN_SALA="12"; //(21oct22)Medico envia cita para que el admnistrador la autorice.
export const ESTATUS_EN_PROCEDIMIENTO="8"; 
export const ESTATUS_EN_RECUPERACION="9"; 


//Se utiliza para TEST (endos135_Termin) Solicitar URL: https://app.bildsoft.com.mx/TEST/services/

//export const ENTORNO="TEST";
//export const SUBDOMINIO="app";

//Se utiliza para PRODUCCION (endos135_agenda)  URL: https://pro.bildsoft.com.mx/PRO/services/
export const ENTORNO="PRO";
export const SUBDOMINIO="tv";

export const FEMENINO="2";
export const MASCULINO="1";

export const UNIDAD_MEDIDA_PRESION_ARTERIAL="1";
export const UNIDAD_MEDIDA_GLUCOSA="2";

export const URL_IMAGES="https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/";


export const MODO_VISTA_DIA="day"
export const MODO_VISTA_SEMANA="week"
export const MODO_VISTA_MES="month"
export const MODO_VISTA_AGENDADOS="AGENDADOS"

