import * as React from 'react';

import SliderContainer from '../SliderContainer';
import SalasContainer from '../SalasContainer';
import { Box, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { CAT_TIPO_CITA, CITA, PACIENTE } from '../../../redux/constants/action-type';


export default function Page(props) {
    const [citaSala,setCitaSala]=useState({});

   
    useEffect(() => {
        const interval = setInterval(() => {
          console.log('Se recarga citas');          
          props.obtenerCatTipoCitaListWSAction(CAT_TIPO_CITA,false);
          props.obtenerPacienteListWSAction(PACIENTE,false);
          props.obtenerCitaListWSAction(CITA,true); /* Invoca por primara vez a productos (default) */

        }, 9000);
        return () => clearInterval(interval);
      }, []);

    return <Box  sx={{display:"flex", flexDirection:"column",justifyContent:"space-between",gridRowGap:"3rem"}} >
                <SalasContainer cita={citaSala}/>
                <SliderContainer citas={props.citas} 
                                 setCitaSala={setCitaSala} 
                                 citaSala={citaSala}/>
            </Box>
}