import React from "react";
import { Route,Switch } from "react-router-dom";
import Login from "../LoginComponent/Login";
import requireAutenticacion from "../../utils/requireAutenticacion"
import CitasTV from "../CitaTVComponent/Cita"

const Page =()=>{
  
        return  <div>
                       <main>                        
                        <Route  exact={true} path="/tv" component={requireAutenticacion(CitasTV)} />
                        <Route  exact={true} path="/login" component={Login} />
                        <Route  exact={true} path="/" component={Login} /> 
                       </main>
                </div>
};

export default Page;






